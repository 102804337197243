import React, {useState} from "react";
import { graphql, useStaticQuery } from "gatsby";
import { ThemeProvider  } from '@mui/styles';
import { createTheme } from '@material-ui/core/styles';
import { styled } from '@mui/styles';
import Layout from "../components/layout";
import "../assets/css/faq.css";

import MUIDataTable from "mui-datatables";
import Input from '@material-ui/core/Input';


const PREFIX = 'FAQPage';

const classes = {
    root: `${PREFIX}-root`,
    input: `${PREFIX}-input`,
    iconButton: `${PREFIX}-iconButton`,
    divider: `${PREFIX}-divider`
};

const StyledLayout = styled(Layout)((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 400
      },

    [`& .${classes.input}`]: {
       width: "50%",
       flex: 1,
       marginBottom: "50px",
      // "&::before": {
      //     left: 0,
      //     right: 0,
      //     bottom: 0,
      //     content: "\00a0",
      //     position: absolute,
      //     transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      //     borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      //     pointerEvents: none,
      //   },
    },

    [`& .${classes.iconButton}`]: {
      padding: 10
    },

    [`& .${classes.divider}`]: {
      height: 28,
      margin: 4
    }
}));

const FAQPage = () => {


    //const muiTheme = getMuiTheme();

    const muiTheme = createTheme({ 
        overrides: {
            MuiPaperRoot: {
                backgroundColor: "#1D252D",
                root: {
                    backgroundColor: "#1D252D",
                    color: "#9DA0B1"
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    backgroundColor: "red"
                }
            }
        }
    });

    const data = useStaticQuery(query);

    const [searchText, setSearchText] = useState("");
    //console.log(data);

    const faqData = data.strapiFaQpage.fa_qs;    

    const options = {
        filter: false,
        responsive: "vertical",
        download: false,
        sort: false,
        selectableRows: false,
        print: false,
        viewColumns: false,
        searchOpen: true,
        searchText: searchText,
        search: false,
        customSearchRender: () => null,
        customFooter: () => null
    }; 
    
    const columns = [
        {
            name: "Question",
            label: "",
            options: {
                customHeadRender: ()=>null
            },
            viewColumns: false
        }, 
        {
            name: "Answer",
            label: "",
            options: {
                customHeadRender: ()=>null
            },        
        }
    ]; 
    //data.strapiPricingPage.cookie_types.map((item) => {};

    return (
        <StyledLayout>        
            <div className="uk-section">
                <div className="uk-container uk-container-large">
                    <div className="faq-wrapper">                        
                        <div className="faq-container">
                            <Input
                                className={classes.input}
                                placeholder="find a question"
                                inputProps={{ "aria-label": "find a question" }}
                                value={searchText}
                                onChange={e => setSearchText(e.target.value)}
                            />
                            <ThemeProvider theme={muiTheme}>
                                {typeof window !== 'undefined' && <MUIDataTable
                                    title={""}
                                    data={faqData}
                                    columns={columns}
                                    options={options}
                                /> }
                            </ThemeProvider>
                        </div>
                    </div>
                </div>
            </div>
        </StyledLayout>
    );
}

const query = graphql`
    query {   
        strapiFaQpage {
            fa_qs {
                Question
                Answer                
            }
        }
    }
`;

export default FAQPage;